const Menu = [
  {
    heading: "menu",
    route: "/training-institute",
    pages: [
      // {
      //   heading: "Training Institute List",
      //   route: "/training-institute/institute-search",
      //   fontIcon: "fa-search",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "Dynamic Training Institute Report",
      //   route: "/training-institute/institute-dynamic-search",
      //   fontIcon: "fa-plus",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   heading: "New Training Institute",
      //   route: "/training-institute/new-institute",
      //   fontIcon: "fa-plus",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      {
        heading: "Add Training Institute Target",
        route: "/training-institute/institute-target",
        fontIcon: "fa-plus",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        sectionTitle: "Institute Mapping",
        route: "/training-institute/mapping",
        fontIcon: "fa-plus",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        sub: [
          {
            heading: "Map Course",
            route: "/training-institute/mapping/course",
          },
          {
            heading: "Map Trainer",
            route: "/training-institute/mapping/trainer",
          },
          {
            heading: "Map Assessor",
            route: "/training-institute/mapping/assessor",
          },
        ],
      },
      // {
      //   heading: "Dynamic Institute Search",
      //   route: "/training-institute/institute-dynamic-search",
      //   fontIcon: "fa-folder",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
    ],
  },
];

export default Menu;
